import * as React from "react"
import Header from '../components/Header';
import mastheadImage from '../images/masthead.jpg';
import { graphql } from "gatsby";
import '../styles/main.css'
import parseMenuItems from "../helpers/parse-menu-items";

// styles
const pageStyles = {
  color: "#232129",
  width: '100%',
  height: '100%',
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}

const tileStyle = {
  flex: '1 1 0px',
  minHeight: '200px',
  margin: '0 1rem',
  padding: '1rem',
  color: '#adadad'
}

const listStyle = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
}

const listItemStyle = {
  margin: '1rem 0',
  padding: 'none'
}

const IndexPage = ({
  data
}) => {
  const menuItems = parseMenuItems(data);
  const APIs = data.site.siteMetadata.APIs.definition;

  return (
    <div style={pageStyles}>
      <Header />

      <div style={{ backgroundImage: `url(${mastheadImage})`, width: '100%', height: '30vh' }} />

      <main>
        <div style={{maxWidth: '1280px', width: '100%', margin: '0 auto'}}>
          <div style={{marginBottom: '1rem'}}>

          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={tileStyle}>
              <h3>API Endpoints</h3>

              <ul style={listStyle}>
                {APIs.map(item =>
                  (<li key={item.name} style={listItemStyle}><a href={item.path}>{item.name}</a></li>)
                )}
              </ul>
            </div>

            <div style={tileStyle}>
              <h3>Concepts</h3>

              <ul style={listStyle}>
                {menuItems.concepts.map(item =>
                  (<li key={item.slug} style={listItemStyle}><a href={item.path}>{item.title}</a></li>)
                )}
              </ul>
            </div>

            <div style={tileStyle}>
              <h3>Walkthroughs</h3>

              <i>Coming soon</i>
            </div>
          </div>
        </div>
      </main>

      <footer style={{ textAlign: 'center', marginTop: '1rem' }}>
        <p>Documentation powered by Gatsby, Stoplight.io & Redoc</p>

        <p>Photo by <a href="https://unsplash.com/@mikenewbry?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mike Newbry</a> on <a href="https://unsplash.com/backgrounds/art?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a></p>
      </footer>
    </div>
  )
}

export default IndexPage;

export const pageQuery = graphql`
query GetMenuItemsAndMetadata {
    allSitePage {
      edges {
        node {
          path
          pageContext
        }
      }
    }
    site {
      siteMetadata {
        APIs {
          definition {
            name
            path
          }
        }
      }
    }
  }  
`;
